import React from 'react'
import {
  ProductMaterial,
  ProductData,
  LoadMoreThanOneProductOptions,
  PaginationProps,
} from '../../types'
import { useMaterialsData } from '../../lib/materials_hook'
import { useProductsData } from '../../lib/products_hooks'
import { isConnectedMaterial } from '../../utils/Mapping'
import { parseFilterUrl } from '../../utils/PuesUtils'
import { withUrlSlugs } from '../generic/withUrlSlugs'
import { useCategoriesData } from '../../lib/products/categories_hook'
import { useRatiosData } from '../../lib/products/ratios_hook'
import { useStylesData } from '../../lib/products/styles_hook'
import { useColorsData } from '../../lib/products/colors_hook'
import { useArtistsData } from '../../lib/artists_hook'
import { mapInternalKey2Slug } from '../../utils/UrlUtils'
import { getHash } from '../../utils/Crypto'
import Pagination from '../generic/Pagination'
import ProductPreview from '../product/Preview'

const LandingPagePues = (options: any) => {
  const categoryData = useCategoriesData()
  const colorData = useColorsData()
  const ratioData = useRatiosData()
  const artistData = useArtistsData()
  const stylesData = useStylesData()
  const urlSlugAll = mapInternalKey2Slug('all')
  const urlfilter = parseFilterUrl(options.urlfilter, categoryData, colorData, ratioData, artistData, stylesData, urlSlugAll)

  const materialsData = useMaterialsData().filter(
    (materialData: ProductMaterial) => {
      return isConnectedMaterial(materialData, options.material)
    },
  )

  const productsDataOptions: LoadMoreThanOneProductOptions = {
    material: options.material,
    page: options.page,
    searchQuery: options.searchQuery,
    categories: urlfilter.categories,
    colors: urlfilter.colors,
    ratios: urlfilter.ratios,
    artists: urlfilter.artists,
    styles: urlfilter.styles,
    sortingOrder: 'bestseller',
  }

  const productsCountOptions: LoadMoreThanOneProductOptions = {
    material: options.material,
    page: options.page,
    searchQuery: options.searchQuery,
    categories: urlfilter.categories,
    colors: urlfilter.colors,
    ratios: urlfilter.ratios,
    artists: urlfilter.artists,
    styles: urlfilter.styles,
    count: true,
  }

  const productsData: ProductData[] = useProductsData(productsDataOptions)
  const productsCount: any = useProductsData(productsCountOptions)

  const paginationProps: PaginationProps = {
    template: options.base_url + '/%d',
    currentPage: parseInt('' + options.page),
    pageCount: Math.ceil(productsCount?.count / 60),
  }

  const signature = getHash(
      JSON.stringify(options.productsDataOptions) +
        JSON.stringify(paginationProps),
    )

  return (
    (
      <div key={signature}>
        <div className="row">
          <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
            {options.hidePagination != true && (
              <Pagination pagination={paginationProps} />
            )}
          </div>
        </div>
        <div className="row">
          {productsData.map((productData: ProductData, i) => (
            <div
              className="col-6 col-sm-4"
              key={signature + 'product_preview_' + i}
            >
              <ProductPreview
                material={'' + options.material}
                materials={materialsData}
                productData={productData}
                variant={options.variant}
              />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
            {options.hidePagination != true && (
              <Pagination pagination={paginationProps} />
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default withUrlSlugs(LandingPagePues)
