const { env } = require('../environment')

const fetchColors = () => {
  const url =
    env.getServiceHost() + 'products/colors?language=' + env.getLanguage()

  return fetch(url).then(response => response.json())
}

module.exports = { fetchColors }
