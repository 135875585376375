const { env } = require('../environment')

const fetchCategories = () => {
  const url =
    env.getServiceHost() +
    'products/categories?version=2&language=' +
    env.getLanguage()

  return fetch(url).then(response => response.json())
}

module.exports = { fetchCategories }
